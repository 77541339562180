@import "../../../style/media-query.scss";

.body {
  background-color: white;
  border-radius: 16px;
  height: fit-content;
  padding: 0 16px 16px;
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: #{$tablet-landscape-width}) {
    padding-left: 24px;
    padding-right: 24px;
  }

  .expandableTitle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .text {
      display: block;
      margin-bottom: 14px;
    }

    .icon {
      display: flex;
      margin-top: 8px;

      svg {
        background-color: var(--primaryYellow);
        font-size: 20px;
        color: var(--charcoalLight);
        border-radius: 50%;
        padding: 6px 7px;
      }
    }
  }
}

button.habitatFiltersButtons {
  background-color: var(--secondaryGreen);
  font-size: 14px;
  font-weight: normal;
  padding: 8px;
  border: 1px solid var(--white);
  width: calc(25% - 8px);
  min-width: fit-content;
}

button.habitatFiltersButtons2 {
  background-color: var(--secondaryGreen);
  font-size: 14px;
  font-weight: normal;
  padding: 8px;
  border: 1px solid var(--white);
  width: calc(16% - 40px);
  min-width: fit-content;
  margin-right: inherit;

  @media screen and (max-width: #{$tablet-portrait-width}) {
    width: 80px;
  }
}

.activeFilterButton,
.habitatFiltersButtons:active,
.habitatFiltersButtons:focus {
  color: rgba(46, 45, 45, 1) !important;
  background-color: var(--white) !important;
}

div.overviewAccordion {
  box-shadow: unset;
  background-color: var(--white);

  >button[type="button"] {
    padding-left: 30px;
    color: black;
    min-height: 35px;
    border: 0;

    svg {
      right: 0;
      left: 10px;
      width: 10px;
      margin: auto 0;
      transform: translateY(-50%) rotateZ(-90deg);
    }
  }

  >div[class*="body"] {
    border: 0;
  }

  &[class*="expand"] {

    >button[type="button"] {

      svg {
        transform: translateY(-50%) rotateZ(0deg);
      }
    }
  }
}

div.welcomeMessageBox {
  padding: 0;

  .topCarouselImage {
    width: 100%;
    height: auto;
    display: flex;
  }

  .content {
    padding: 20px 16px 24px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .welcomeMessageFirstLine {
      margin: 0 0 10px 0;
      font-size: 18px;
      font-weight: 700;
      color: var(--charcoal);
    }

    .welcomeMessageDescription {
      margin: 0;
      font-size: 16px;
      color: var(--charcoalLight);
    }
  }
}
