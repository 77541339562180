@keyframes pulse {

  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: .4;
  }

  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.habitatImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-left: 6px;
  flex-shrink: 0;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .tag {
    position: absolute;
    bottom: 0;
    transform: translate3d(0, 75%, 0);

    .habitat {
      min-width: 41px;
      width: max-content;
      height: 16px;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      padding: 2px;
    }

  }

  .liveTalkBadge {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primaryYellow);
    color: rgba(46, 45, 45, 1);
    padding: 0 3px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    white-space: nowrap;
    border-radius: 6px;
  }

  .notificationDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    right: -8px;
  }

  &.freemium .notificationDot {
    background-color: var(--pink);
  }

  &.online .notificationDot {
    background-color: var(--onlineGreen);
    animation: pulse 1.2s infinite ease-out;
  }


  &.offline .notificationDot {
    background-color: var(--offlineGrey);
  }

}

.leftAlignedIcon {

  >button {

    svg {
      left: 0px !important;
      right: unset !important;
    }
  }
}

div.mapAccordion {
  box-shadow: unset;
  border-radius: 0;
  border-bottom: 1.5px solid #DFDFDF;

  &:last-child {
    border-bottom: 0;
  }

  >button[type="button"] {
    padding: 0;
    color: black;
    transition-delay: .3s;
    border: 0;

    svg {
      right: 0;
      width: 10px;
    }
  }

  &[class*="expand"]>button[type="button"] {
    border: 0;
  }

  >div[class*="body"] {
    border: 0;
  }
}

.viewButton {
  cursor: pointer;
  color: var(--primaryGreen);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border: 2px solid var(--primaryGreen);
  padding: 4px 16px;
  background-color: white;
  border-radius: 9999px;
  text-decoration: none;
  flex-shrink: 0;
  margin-left: 8px;

  @media (hover: hover) {

    &:hover:not(:disabled) {
      color: white;
      background-color: var(--primaryGreen);
    }
  }
}

button.trailerButton {
  margin-bottom: 12px;

  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .viewButton;
}

.habitatTitleHeading {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}

.zooName {
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
}

.descriptionContainer {
  padding: 0 8px 10px 28px;
}
