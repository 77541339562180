.close {
  position: absolute;
  top: calc(50% - (50 * var(--vh)) + 20px);
  right: calc(50% - 50vw + 20px);
  z-index: 1;
  width: 50px;
  height: 50px;
  background: var(--hunterGreenMediumDark);
  color: white;
}

div.contentContainer {
  flex-grow: 1;
  padding: 0;
  overflow-y: auto;

  .contentWrapper {
    flex-grow: 1;
    flex-direction: row;
  }

  .wrapper {
    padding: 26px;
    display: flex;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;

    .form {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      width: 100%;
    }

    .input {
      border-width: 0 0 1px 0;
      border-radius: 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 4px;
      padding-left: 0;

      &::placeholder {
        font-size: 10px;
        color: #CDCDCD;
      }
    }
  }

  .button {
    width: fit-content;
    align-self: center;
  }
}
