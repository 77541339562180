@import "../../style/media-query.scss";

// width threshold is based ONLY on the design not devices
$widthThreshold: 800px;
$circle-size: 40px;

.container {
  width: 100%;
  height: 100%;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-self: center;
    max-width: 1200px;
    width: 100%;
    height: 100%;
  }
}

.pickSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 76px;

  @media screen and (max-width: 1000px) {
    padding: 40px 20px;
  }

  @media screen and (max-width: 450px) and (min-width: 415px) {
    padding: 40px 15px;
  }
}

.divider {
  background-color: #EBEBEB;
  width: 1px;
}

.characterSection {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 76px;

  @media screen and (max-width: 1000px) {
    padding: 40px;
  }

  > div {
    max-width: 500px;
    flex-grow: 1;

    @media screen and (max-width: 1000px) {
      width: auto;
    }
  }
}

.circleItem {
  width: $circle-size;
  height: $circle-size;
  background-color: currentColor;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  position: relative;
}

.selected {
  outline: 3px solid currentColor !important;
  outline-offset: 2px;
}

.animalIcon {
  padding: 3px;

  .icon {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    color: var(--white);
  }
}

.largeImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}

.largeImg {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background-color: #FFB145;
  padding: 20px;
  display: flex;
  position: relative;

  button {
    width: 50px;
    height: 50px;
    color: var(--blueDark);
    background: white;
    border-radius: 50%;
    position: absolute;
    border: 0;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, .25));
    right: -15px;
    top: 15px;
    font-size: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: .9;
    }
  }

  img {
    width: 100%;
  }
}

.colorPickerWrapper {
  position: relative;

}

.pickerBtn {
  width: $circle-size;
  height: $circle-size;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  background-color: #EBEBEB;
}

.picker {
  position: absolute;
  top: -170px;
  right: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .15);
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;

  button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 6px 6px 0 0;
    line-height: initial;

    svg {
      font-size: 20px;
      color: var(--charcoal);
    }
  }

  p {
    margin-top: 0;
    font-size: 14px;
    text-align: left;
  }
}

.modalContainer {
  position: relative;
  border-radius: 7px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  margin: auto;
  background: white;
  color: black;

  .close {
    position: absolute;
    right: 15px;
    top: 15px;

    svg {
      width: 16px;
      height: auto;
    }
  }
}

@media screen and (max-width: #{$widthThreshold}) {

  .container .wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .pickSection {
    flex-basis: 100%;
  }

  .divider {
    display: none;
  }

  .characterSection button {
    margin: auto;
  }
}
