@import "../../style/media-query";

.container {
  border-radius: 16px;

  @media screen and (min-width: #{$tablet-landscape-width + 1}) {
    min-width: 327px;
    min-height: 284px;
    max-height: 575px;
    max-width: 327px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.layer {
  overflow: hidden;
  width: 327px;
  border-radius: 16px;
}

.contentWrapper {
  padding: 0 10px;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.header {
  position: relative;

  @media screen and (min-width: #{$tablet-landscape-width + 1}) {
    min-width: 327px;
  }

  img {
    width: 100%;
  }
}

.bellIcon {
  padding-right: 10px;
}

.headingText {
  color: rgba(9, 10, 10, 1);
  font-weight: 700 !important;
}

.description {
  color: var(--descriptionGrey);
  font-size: 16px;
  font-weight: 400;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
